import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { IXtContext, XtContext } from "../../../../../Xt/Contexts/XtContext";
import { DateUtil } from "../../../../../Xt/Utils/DateUtil";
import { getBambooEmployeeList } from "../../../../../api/react-query";
import { MINUTE } from "../../../../../utils/constants";
import FormField from "../Components/FormField";
import FormLabelMutedText from "../Components/FormLabelMutedText";

export default function PrrGeneralInfoReadOnly(props) {
  const [replacementEmployeeName, setReplacementEmployeeName] = useState<
    string | null
  >(null);
  const prr = props.prr;
  const isDirect = prr?.employeeType === 'True';

  const context: IXtContext = useContext(XtContext);

  const token = context?.tokens?.accessToken;
  const username = context?.loggedOnUser?.username;

  const { data: employeeList } = useQuery(
    ["bambooEmployeeList", token, username],
    getBambooEmployeeList,
    {
      enabled: !!token && !!username,
      placeholderData: [],
      staleTime: Infinity,
      cacheTime: 20 * MINUTE,
    }
  );

  useEffect(() => {
    if (!employeeList || prr?.requestReason !== "Replacement") return;

    const employee = employeeList.find(
      (e) => e.value === prr?.replacementEmployee
    );
    setReplacementEmployeeName(employee?.text);
  }, [employeeList, prr?.requestReason, prr?.replacementEmployee]);

  return (
    <>
      <Row>
        <Col>
          <FormField label="PRR No.">
            <FormLabelMutedText value={prr?.prrNumber} />
          </FormField>

          <FormField label="Created By">
            <FormLabelMutedText value={prr?.createdByDisplayName} />
          </FormField>

          <FormField label="Date Created">
            <FormLabelMutedText
              value={DateUtil.formatmmDdYYY(prr?.createdOn)}
            />
          </FormField>

          <FormField label="Date Needed">
            <FormLabelMutedText
              value={DateUtil.formatmmDdYYY(prr?.dateNeeded)}
            />
          </FormField>
        </Col>
        <Col>
          <FormField label="Employee Type">
            <FormLabelMutedText
              value={isDirect ? "Direct" : "Indirect"}
            />
          </FormField>
          <FormField label="Employing Company">
            <FormLabelMutedText value={prr?.employingCompany} />
          </FormField>
          {isDirect ? (
            <>
              <FormField label="Project No.">
                <FormLabelMutedText value={prr?.projectName} />
              </FormField>
            </>
          ) : (
            <>
              <FormField label="Charge No.">
                <FormLabelMutedText value={prr?.projectNumber} />
              </FormField>
            </>
          )}

          <FormField label="Reason for request">
            {prr?.requestReason && (
              <FormLabelMutedText
                value={`${
                  prr?.requestReason === "Replacement"
                    ? "Replacement (" + replacementEmployeeName + ")"
                    : prr?.requestReason
                }`}
              />
            )}
          </FormField>
        </Col>
      </Row>
    </>
  );
}
